import {
  CheckCircleFilled,
  PlusCircleOutlined,
  MinusCircleFilled,
  RightOutlined,
  MailOutlined,
  LeftOutlined,
  CheckOutlined,
  RightCircleFilled,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Steps,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { Questionario } from "../../../../context/mapeamentoContext";
import { CapituloType } from "../../../../context/processosContext";
import QuestionarioPreliminar from "./components/QuestionarioPreliminar";
import "./styles.css";

interface MapeamentoProps {
  currentMapeamento: Questionario | null | undefined; // ou qualquer outro tipo apropriado
  setCurrentMapeamento: (mapeamento: Questionario | null) => void;
}

const Mapeamento: React.FC<MapeamentoProps> = ({
  currentMapeamento,
  setCurrentMapeamento,
}) => {
  const {
    userInfo,
    moduloMapeamento,
    moduloProcessos,
    postPrimeiroAcesso,
    postUser,
    patchUser,
    usuarios,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [clickedProcesso, setClickedProcesso] = useState<any | null>(null);
  const [concluirStep1, setConcluirStep1] = useState<boolean>(false);
  const [concluirStep2, setConcluirStep2] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [currentUserGestor, setCurrentUserGestor] = useState<any>(null);
  const [usersLideres, setUsersLideres] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [finishStep3, setFinishStep3] = useState(false);
  const [showQuestionario, setShowQuestionario] = useState(false);
  const [newProcess, setNewProcess] = useState<any>({});
  const [reutilizarLider, setReutilizarLider] = useState(false);
  const [currentUserResponsavel, setCurrentUserResponsavel] = useState(false);
  const [criarNovoUsuario, setCriarNovoUsuario] = useState(false);
  const [selectedLider, setSelectedLider] = useState(null);
  const [clickedArea, setClickedArea] = useState<any | null>(null);
  const [showModalStep1, setShowModalStep1] = useState<boolean>(false);
  const params = useParams();

  const showModal = () => {
    setOpen(true);
  };

  const [form] = Form.useForm();
  const [formPreliminar] = Form.useForm();

  const [executedOnceStep1, setExecutedOnceStep1] = useState(false);
  const [executedOnceStep2, setExecutedOnceStep2] = useState(false);
  const [executedOnceStep3, setExecutedOnceStep3] = useState(false);

  useEffect(() => {
    if (
      currentMapeamento?.capitulos &&
      currentMapeamento.capitulos.filter((cap: any) => !cap.dependencia)
        ?.length > 0
    ) {
      setConcluirStep1(true);
      setExecutedOnceStep1(true);
    } else {
      !executedOnceStep1 && setConcluirStep1(false);
      setExecutedOnceStep1(true);
    }
  }, [currentMapeamento, executedOnceStep1]);

  useEffect(() => {
    if (
      currentMapeamento?.capitulos &&
      currentMapeamento.capitulos?.filter((cap: any) => !cap.dependencia)
        ?.length > 0
    ) {
      const usersLideres = usuarios?.filter(
        (user: any) =>
          user.funcoesDetalhadas.find(
            (funcao: any) =>
              funcao.funcao_id.name === "responder-processo" &&
              currentMapeamento?.capitulos?.find(
                (c: any) => c._id === funcao.reference_model_id
              )
          ) &&
          user.funcoesDetalhadas.find(
            (funcao: any) =>
              funcao.funcao_id.name === "responder-questionario" &&
              funcao.reference_model_id === currentMapeamento._id
          )
      );

      setUsersLideres(usersLideres);

      if (
        usersLideres?.length > 0

        // currentMapeamento.capitulos?.filter((cap: any) => !cap.dependencia)
        //   ?.length
      ) {
        setConcluirStep2(true);
        !executedOnceStep2 && setStep(2);
        setExecutedOnceStep2(true);

        setClickedProcesso(currentMapeamento?.capitulos[0]);

        const userGestor = usuarios.find(
          (user: any) =>
            user.funcoesDetalhadas.find(
              (funcao: any) =>
                funcao.funcao_id.name === "responder-processo" &&
                funcao.reference_model_id ===
                  currentMapeamento?.capitulos?.filter(
                    (cap: any) => !cap.dependencia
                  )?.[0]._id
            ) &&
            user.funcoesDetalhadas.find(
              (funcao: any) =>
                funcao.funcao_id.name === "responder-questionario" &&
                funcao.reference_model_id === currentMapeamento._id
            )
        );
        setCurrentUserGestor(userGestor);
        if (userGestor)
          form.setFieldsValue({
            name: userGestor.name,
            cargo: userGestor.cargo,
            email: userGestor.email,
            telefone: userGestor.telefone,
          });
      }
    }
  }, [currentMapeamento, usuarios, executedOnceStep2]);

  useEffect(() => {
    if (
      step === 2 &&
      !usersLideres?.filter(
        (usr: any) =>
          !usr.envios?.find(
            (envio: any) =>
              envio.subject === "Seusdados - Bem-vindo à Plataforma Workday"
          )
      )[0] &&
      !executedOnceStep3
    ) {
      showModal();
      setFinishStep3(true);
      setExecutedOnceStep3(true);
    }
  }, [usersLideres, step, executedOnceStep3]);

  const getCurrentStep = (): number => {
    if (step) {
      return step;
    }
    // if (currentMapeamento?.areas && currentMapeamento.areas.length > 0 && clickedArea) {
    //   return 1; // Or other logic to determine the step based on the state of `currentMapeamento`
    // }
    return 0;
  };

  const toggleProcesso = (processo: CapituloType) => {
    if (currentMapeamento) {
      const currentProcessos = currentMapeamento.capitulos || [];

      const newProcesso = currentProcessos.find(
        (p: any) => p._id === processo._id
      )
        ? [
            ...currentProcessos.filter((p: any) => p._id !== processo._id),
            {
              ...currentProcessos.find((p: any) => p._id === processo._id),
              inativo: !currentProcessos.find(
                (p: any) => p._id === processo._id
              ).inativo,
            },
          ]
        : [
            ...currentProcessos,
            { ...processo, areas_id: [currentMapeamento?.areas?.[0]] },
          ];
      setCurrentMapeamento({ ...currentMapeamento, capitulos: newProcesso });

      setClickedProcesso(processo._id);
    }
  };

  const handleAddProcess = (area: string | undefined) => {
    if (area) {
      // posta o novo processo e retorna o _id
      moduloProcessos
        ?.postCapitulo({
          id: null,
          description: newProcess?.processo,
          norma: "",
          recomendacao: "",
          tags: [area],
          dependencia: null,
        })
        .then((processo: any) => toggleProcesso(processo._id));

      setNewProcess("");
    }
  };

  const onFinish = (values: any) => {
    let permissoesParaAdicionar = [
      // permissao responder-processo

      {
        reference_model_id: clickedProcesso._id,
        funcao_id: "65f8a0c1c0e72d990258afa4",
      },
      // responder-questionario
      {
        reference_model_id: currentMapeamento?._id,
        funcao_id: "65fb2140c0e72d990258afc0",
      },
      // cadastrar-parceiro-fornecedor
      {
        reference_model_id: currentMapeamento?.cliente_id,
        funcao_id: "65eefbc6ff2de399bce35a77",
      },
      // view-parceiro-fornecedor
      {
        reference_model_id: currentMapeamento?.cliente_id,
        funcao_id: "65eefd46ff2de399bce35a80",
      },
    ];

    if (selectedLider) {
      permissoesParaAdicionar = permissoesParaAdicionar.filter(
        (p) =>
          !usuarios
            .find((usr: any) => usr._id === selectedLider)
            ?.funcoesDetalhadas?.map((permissao: any) => {
              return {
                key: permissao._id,
                name: permissao.funcao_id.name,
                description: permissao.funcao_id.description,
                reference_model_name: permissao.funcao_id.reference_model_name,
                reference_model_id: permissao.reference_model_id,
                funcao_id: permissao.funcao_id._id,
              };
            })
            .find(
              (permExistente: any) =>
                permExistente.referece_model_id === p.reference_model_id &&
                permExistente.funcao_id === p.funcao_id
            )
      );

      patchUser({
        ...usuarios.find((usr: any) => usr._id === selectedLider),
        user_id: selectedLider,
        permissoes: [
          ...usuarios
            .find((usr: any) => usr._id === selectedLider)
            ?.funcoesDetalhadas?.map((permissao: any) => {
              return {
                key: permissao._id,
                name: permissao.funcao_id.name,
                description: permissao.funcao_id.description,
                reference_model_name: permissao.funcao_id.reference_model_name,
                reference_model_id: permissao.reference_model_id,
                funcao_id: permissao.funcao_id._id,
              };
            }),
          ...permissoesParaAdicionar,
        ],
      });
    } else {
      postUser({
        ...values,
        cliente_id: currentMapeamento?.cliente_id,
        grupos: ["65f8a126c0e72d990258afa9"], // grupo lider processo
        permissoes: [...permissoesParaAdicionar],
      });
    }
  };

  return showQuestionario ? (
    <div className="mapeamentos">
      {!userInfo?.grupos?.find(
        (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
      ) && (
        <>
          <h1>Olá, {userInfo?.name?.split(" ")[0]}</h1>
          <br />
          <p>
            Você, como gestor da área de{" "}
            <b>
              {
                moduloProcessos?.areas?.find(
                  (area) => area._id === currentMapeamento?.areas?.[0]
                )?.description
              }
            </b>
            , precisamos que você responda algumas perguntas sobre os processos
            abaixo.
          </p>
          <br />
        </>
      )}

      <div className="mapeamentos_containner">
        <div
          className="mapeamentos_steps"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div>
            <h5>Selecione uma área e responda:</h5>
            <br />
            {currentMapeamento?.areas
              ?.filter(
                (area: any) =>
                  moduloProcessos.areas?.find((a) => a._id === area)
                    ?.description
              )
              ?.map((area: any) => {
                return (
                  <div>
                    <Button
                      key={area}
                      type={clickedArea?._id === area ? "primary" : "default"}
                      style={{ marginBottom: "0.5rem", background: "#3333FF" }}
                      size="small"
                      icon={
                        false ? (
                          <CheckCircleFilled style={{ color: "#3333FF" }} />
                        ) : null
                      }
                      onClick={() => {
                        formPreliminar.resetFields();
                        setClickedArea(area);

                        // if (userGestor)
                        //   form.setFieldsValue({
                        //     name: userGestor.name,
                        //     cargo: userGestor.cargo,
                        //     email: userGestor.email,
                        //     telefone: userGestor.telefone,
                        //   });
                      }}
                    >
                      {
                        moduloProcessos.areas?.find((a) => a._id === area)
                          ?.description
                      }
                      {true && (
                        <RightCircleFilled
                          style={{
                            color:
                              clickedArea?._id === area ? "#fff" : "#3333FF",
                          }}
                        />
                      )}
                    </Button>
                  </div>
                );
              })}
            <br />
            <br />
          </div>
          <div className="d-flex j-b">
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => setShowQuestionario(false)}
              style={{ color: "#A3A3A3" }}
            >
              Etapa anterior
            </Button>
          </div>
        </div>
        <div
          className={
            clickedArea
              ? "mapeamentos_steps triangulo"
              : "mapeamentos_steps--hidden"
          }
          style={{ width: "150%" }}
        >
          {clickedArea && currentMapeamento && (
            <>
              <h5>
                Questionário preliminar:{" "}
                {
                  moduloProcessos.areas?.find((a) => a._id === clickedArea)
                    ?.description
                }
              </h5>
              <br />
              <QuestionarioPreliminar
                mapeamento={currentMapeamento}
                area={clickedArea}
              />
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="mapeamentos">
      {!userInfo?.grupos?.find(
        (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
      ) && (
        <>
          <h1>Olá, {userInfo?.name?.split(" ")[0]}</h1>
          <br />
          <p>
            {currentMapeamento?.areas?.filter(
              (areaCurrentMap) =>
                moduloProcessos?.areas?.find(
                  (area) => area._id === areaCurrentMap
                )?.description
            )?.[0] &&
            currentMapeamento?.areas?.filter(
              (areaCurrentMap) =>
                moduloProcessos?.areas?.find(
                  (area) => area._id === areaCurrentMap
                )?.description
            )?.length > 1
              ? "Você, como gestor das áreas "
              : "Você, como gestor da área de "}
            <b>
              {currentMapeamento?.areas &&
                (currentMapeamento?.areas?.length > 1
                  ? currentMapeamento?.areas
                      ?.filter(
                        (areaCurrentMap) =>
                          moduloProcessos?.areas?.find(
                            (area) => area._id === areaCurrentMap
                          )?.description
                      )
                      ?.map(
                        (areaCurrentMap: any) =>
                          moduloProcessos?.areas?.find(
                            (area) => area._id === areaCurrentMap
                          )?.description
                      )
                      ?.reduce((acc, area, index, array) => {
                        if (index === 0) {
                          return area;
                        } else if (index === array.length - 1) {
                          return `${acc} e ${area}`;
                        } else {
                          return `${acc}, ${area}`;
                        }
                      }, "")
                  : moduloProcessos?.areas?.find(
                      (area) => area._id === currentMapeamento?.areas?.[0]
                    )?.description)}
            </b>
            , deve adicionar os processos de tratamento de dados pessoais
            existentes na sua área e definir quem é o líder ou responsável por
            cada um deles.
          </p>
          <br />
        </>
      )}
      {currentMapeamento?.areas?.[0] ? (
        <>
          <div className="mapeamentos_steps">
            <Steps
              current={getCurrentStep()}
              items={[
                {
                  title: "Adicionar processos",
                  description: (
                    <span
                      style={{
                        color:
                          step === 0
                            ? "#0368C8"
                            : step > 0
                            ? "#008C76"
                            : "#A3A3A3",
                        fontSize: "0.6rem",
                      }}
                    >
                      {step === 0
                        ? "Em andamento"
                        : step > 0
                        ? "Concluído"
                        : ""}
                    </span>
                  ),
                  subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 1</p>,
                },
                {
                  title: "Definir líderes",
                  description: (
                    <span
                      style={{
                        color:
                          step === 1
                            ? "#0368C8"
                            : step > 1
                            ? "#008C76"
                            : "#A3A3A3",
                        fontSize: "0.6rem",
                      }}
                    >
                      {step === 1
                        ? "Em andamento"
                        : step > 1
                        ? "Concluído"
                        : "Pendente"}
                    </span>
                  ),
                  subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 2</p>,
                },
                {
                  title: "Revisão",
                  description: (
                    <span
                      style={{
                        color:
                          step === 2
                            ? "#0368C8"
                            : step > 2
                            ? "#008C76"
                            : "#A3A3A3",
                        fontSize: "0.6rem",
                      }}
                    >
                      {step === 2
                        ? "Em andamento"
                        : step > 1
                        ? "Concluído"
                        : "Pendente"}
                    </span>
                  ),
                  subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 3</p>,
                  status: finishStep3
                    ? "finish"
                    : step === 2
                    ? "process"
                    : "wait",
                },
              ]}
            />
          </div>
          <br />
          <div className="mapeamentos_containner">
            {getCurrentStep() === 0 && (
              <>
                <div className="mapeamentos_steps">
                  <h5>
                    Selecione ou adicione os processos existentes na sua área:
                  </h5>
                  <br />
                  {currentMapeamento?.areas &&
                  currentMapeamento?.areas?.length > 1 ? (
                    currentMapeamento?.areas
                      ?.filter(
                        (areaCurrentMap) =>
                          moduloProcessos?.areas?.find(
                            (area) => area._id === areaCurrentMap
                          )?.description
                      )
                      ?.map((area: any) => (
                        <div>
                          <p>
                            {
                              moduloProcessos?.areas?.find(
                                (a: any) => a._id === area
                              )?.description
                            }
                          </p>
                          <div
                            style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                          >
                            {moduloProcessos?.capitulos
                              ?.filter((processo) =>
                                processo.tags?.includes(area)
                              )
                              .map((processo) => (
                                <div>
                                  <Button
                                    key={processo._id}
                                    style={{ marginBottom: "0.5rem" }}
                                    size="small"
                                    icon={
                                      currentMapeamento?.capitulos?.find(
                                        (proc: any) =>
                                          proc._id === processo._id &&
                                          !proc.inativo
                                      ) ? (
                                        <CheckCircleFilled
                                          style={{ color: "#3333FF" }}
                                        />
                                      ) : (
                                        <PlusCircleOutlined />
                                      )
                                    }
                                    onClick={() => toggleProcesso(processo)}
                                  >
                                    {processo.description}
                                  </Button>
                                </div>
                              ))}
                            {/* Campo para adicionar novo processo */}
                            <Space
                              style={{
                                marginTop: "0rem",
                                marginBottom: "1rem",
                              }}
                            >
                              <Input
                                value={
                                  newProcess.area_id === area
                                    ? newProcess.processo
                                    : ""
                                }
                                size="small"
                                onChange={(e) =>
                                  setNewProcess({
                                    processo: e.target.value,
                                    area_id: area,
                                  })
                                }
                                placeholder="Novo processo"
                                style={{ width: 200 }}
                              />
                              <Button
                                size="small"
                                type="primary"
                                onClick={() => handleAddProcess(area)}
                                disabled={newProcess.area_id !== area}
                                icon={<PlusCircleOutlined />}
                              >
                                Adicionar
                              </Button>
                            </Space>
                          </div>
                        </div>
                      ))
                  ) : (
                    <>
                      {moduloProcessos?.capitulos
                        ?.filter((processo) =>
                          processo.tags?.find(
                            (area) => area === currentMapeamento?.areas?.[0]
                          )
                        )
                        .map((processo) => (
                          <>
                            <div>
                              <Button
                                key={processo._id}
                                style={{ marginBottom: "0.5rem" }}
                                size="small"
                                icon={
                                  currentMapeamento?.capitulos?.find(
                                    (proc: any) => proc._id === processo._id
                                  ) ? (
                                    <CheckCircleFilled
                                      style={{ color: "#3333FF" }}
                                    />
                                  ) : (
                                    <PlusCircleOutlined />
                                  )
                                }
                                onClick={() => toggleProcesso(processo)}
                              >
                                {processo.description}
                              </Button>
                            </div>
                          </>
                        ))}
                      {/* Campo para adicionar novo processo */}
                      <Space style={{ marginTop: "1rem" }}>
                        <Input
                          value={
                            newProcess.area_id === currentMapeamento?.areas?.[0]
                              ? newProcess.processo
                              : ""
                          }
                          size="small"
                          onChange={(e) =>
                            setNewProcess({
                              processo: e.target.value,
                              area_id: currentMapeamento?.areas?.[0],
                            })
                          }
                          placeholder="Novo processo"
                          style={{ width: 200 }}
                        />
                        <Button
                          size="small"
                          type="primary"
                          onClick={() =>
                            handleAddProcess(currentMapeamento?.areas?.[0])
                          }
                          disabled={
                            newProcess.area_id !== currentMapeamento?.areas?.[0]
                          }
                          icon={<PlusCircleOutlined />}
                        >
                          Adicionar
                        </Button>
                      </Space>
                    </>
                  )}
                </div>
                <div
                  className={
                    currentMapeamento?.capitulos?.length &&
                    currentMapeamento?.capitulos?.length > 0
                      ? "mapeamentos_steps triangulo"
                      : "mapeamentos_steps--hidden"
                  }
                >
                  {currentMapeamento?.capitulos &&
                    currentMapeamento?.capitulos?.length > 0 && (
                      <>
                        <h5>Processos adicionados</h5>
                        <br />
                        {currentMapeamento?.capitulos
                          ?.filter(
                            (cap: any) => !cap.dependencia && !cap.inativo
                          )
                          ?.map((cap: any) => (
                            <div>
                              <Tooltip title="Remover processo?">
                                <Button
                                  style={{ marginBottom: "0.5rem" }}
                                  type="primary"
                                  key={cap._id}
                                  size="small"
                                  icon={<MinusCircleFilled style={{}} />}
                                  onClick={() => toggleProcesso(cap)}
                                >
                                  {cap.description}
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                        <br />
                        <br />
                        <Button
                          style={{ background: "#32E6BE", color: "#333333" }}
                          size="small"
                          type="primary"
                          onClick={() => {
                            moduloMapeamento?.saveQuestionario(
                              currentMapeamento?.cliente_id,
                              {
                                ...currentMapeamento,
                                perguntasRelacao:
                                  moduloProcessos?.capituloPerguntas?.filter(
                                    (cp: any) =>
                                      currentMapeamento.capitulos
                                        .map((c: any) => c._id)
                                        .includes(cp.capitulo_id)
                                  ),
                              }
                            );
                            if (
                              currentMapeamento?.capitulos?.find(
                                (cap: any) => !cap.dependencia && !cap.inativo
                              )
                            ) {
                              setConcluirStep1(true);
                              setShowModalStep1(true);
                            }
                          }}
                        >
                          Concluir
                        </Button>
                      </>
                    )}
                </div>
                <div
                  className={
                    concluirStep1 && currentMapeamento?.capitulos?.[0]
                      ? "mapeamentos_steps triangulo"
                      : "mapeamentos_steps--hidden"
                  }
                >
                  {concluirStep1 && currentMapeamento?.capitulos?.[0] && (
                    <div
                      onClick={() => console.log(currentMapeamento.capitulos)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CheckCircleFilled
                        style={{ fontSize: "80px", color: "#32E6BE" }}
                      />
                      <br />
                      <h2>Etapa concluída</h2>
                      <br />
                      <br />
                      <Button
                        onClick={() => setStep(1)}
                        type="text"
                        style={{ color: "#008C76" }}
                      >
                        Ir para próxima etapa <RightOutlined />
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
            {getCurrentStep() === 1 && (
              <>
                <div className="mapeamentos_steps">
                  <h5>Defina um líder para os seguintes processos:</h5>
                  <br />
                  {currentMapeamento?.capitulos
                    ?.filter((cap: any) => !cap.dependencia && !cap.inativo)
                    .map((cap: any) => {
                      const userGestor = usuarios?.find(
                        (user: any) =>
                          user.funcoesDetalhadas.find(
                            (funcao: any) =>
                              funcao.funcao_id.name === "responder-processo" &&
                              funcao.reference_model_id === cap._id
                          ) &&
                          user.funcoesDetalhadas.find(
                            (funcao: any) =>
                              funcao.funcao_id.name ===
                                "responder-questionario" &&
                              funcao.reference_model_id ===
                                currentMapeamento._id
                          )
                      );

                      return (
                        <div>
                          <Button
                            key={cap._id}
                            style={{ marginBottom: "0.5rem" }}
                            size="small"
                            icon={
                              userGestor ? (
                                <CheckCircleFilled
                                  style={{ color: "#3333FF" }}
                                />
                              ) : (
                                <PlusCircleOutlined />
                              )
                            }
                            onClick={() => {
                              form.resetFields();
                              setClickedProcesso(cap);

                              setCurrentUserGestor(userGestor);

                              if (userGestor)
                                form.setFieldsValue({
                                  name: userGestor.name,
                                  cargo: userGestor.cargo,
                                  email: userGestor.email,
                                  telefone: userGestor.telefone,
                                });
                            }}
                          >
                            {cap.description}
                          </Button>
                        </div>
                      );
                    })}
                </div>
                <div
                  className={
                    getCurrentStep() >= 1 &&
                    clickedProcesso &&
                    moduloProcessos.capitulos?.find(
                      (c) => c._id === clickedProcesso?._id
                    )?.description
                      ? "mapeamentos_steps triangulo"
                      : "mapeamentos_steps--hidden"
                  }
                >
                  {getCurrentStep() >= 1 && clickedProcesso?._id && (
                    <>
                      <h5>
                        Dados do líder:{" "}
                        {
                          moduloProcessos.capitulos?.find(
                            (c) => c._id === clickedProcesso?._id
                          )?.description
                        }
                      </h5>
                      <br />

                      <Form
                        disabled={currentUserGestor}
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                      >
                        {usuarios?.find(
                          (u: any) =>
                            u.cliente_id === currentMapeamento?.cliente_id
                        ) && (
                          <>
                            <Checkbox
                              checked={currentUserResponsavel}
                              onChange={() => {
                                setSelectedLider(userInfo._id);
                                setCurrentUserResponsavel(true);
                                setReutilizarLider(false);
                                setCriarNovoUsuario(false);
                              }}
                              style={{ marginBottom: "1rem" }}
                            >
                              Quero ser o responsável
                            </Checkbox>
                            <Checkbox
                              checked={reutilizarLider}
                              onChange={() => {
                                setReutilizarLider(!reutilizarLider);

                                setCurrentUserResponsavel(false);
                                setCriarNovoUsuario(false);
                              }}
                              style={{ marginBottom: "1rem" }}
                            >
                              Utilizar usuário já cadastrado
                            </Checkbox>
                            <Checkbox
                              checked={criarNovoUsuario}
                              onChange={() => {
                                setCriarNovoUsuario(!criarNovoUsuario);

                                setReutilizarLider(false);
                                setCurrentUserResponsavel(false);
                              }}
                              style={{ marginBottom: "1rem" }}
                            >
                              Cadastrar novo usuário
                            </Checkbox>
                          </>
                        )}

                        {reutilizarLider && (
                          <Form.Item>
                            <Select
                              placeholder="Selecione um líder existente"
                              onChange={(v) => setSelectedLider(v)}
                              allowClear
                              options={usuarios
                                ?.filter(
                                  (u: any) =>
                                    u.cliente_id ===
                                    currentMapeamento?.cliente_id
                                )
                                ?.map((usr: any) => {
                                  return {
                                    value: usr._id,
                                    label: usr.name.toUpperCase(),
                                  };
                                })}
                            />
                          </Form.Item>
                        )}

                        {(currentUserGestor ||
                          (!reutilizarLider &&
                            !currentUserResponsavel &&
                            criarNovoUsuario)) && (
                          <>
                            <Form.Item
                              label="Nome"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "O nome é obrigatório!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Cargo"
                              name="cargo"
                              rules={[
                                {
                                  required: true,
                                  message: "O cargo é obrigatório!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="E-mail"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "O e-mail é obrigatório!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Telefone"
                              name="telefone"
                              rules={[
                                {
                                  required: true,
                                  message: "O telefone é obrigatório!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </>
                        )}
                        {!currentUserGestor &&
                          (reutilizarLider ||
                            currentUserResponsavel ||
                            criarNovoUsuario) && (
                            <Form.Item>
                              <Button
                                size="small"
                                style={{
                                  background: "#32E6BE",
                                  color: "#333333",
                                }}
                                type="primary"
                                htmlType="submit"
                              >
                                Concluir
                              </Button>
                            </Form.Item>
                          )}
                      </Form>

                      {currentUserGestor && (
                        <Button
                          disabled
                          size="small"
                          type="primary"
                          htmlType="submit"
                        >
                          Alterar
                        </Button>
                      )}
                      {!concluirStep2 && (
                        <>
                          <Button
                            icon={<LeftOutlined />}
                            type="text"
                            onClick={() => setStep(0)}
                            style={{ color: "#A3A3A3", marginLeft: "-1rem" }}
                          >
                            Etapa anterior
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={
                    concluirStep2
                      ? "mapeamentos_steps triangulo"
                      : "mapeamentos_steps--hidden"
                  }
                >
                  {concluirStep2 && (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleFilled
                          style={{ fontSize: "80px", color: "#32E6BE" }}
                        />
                        <br />
                        <h2>Etapa concluída</h2>
                        <br />
                        <Button
                          onClick={() => setStep(2)}
                          type="text"
                          style={{ color: "#008C76" }}
                        >
                          Ir para próxima etapa <RightOutlined />
                        </Button>
                      </div>
                      <div className="d-flex j-b">
                        <Button
                          icon={<LeftOutlined />}
                          type="text"
                          onClick={() => setStep(0)}
                          style={{ color: "#A3A3A3" }}
                        >
                          Etapa anterior
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {getCurrentStep() === 2 && (
            <div className="mapeamentos_containner">
              <div className="mapeamentos_steps" style={{ width: "130%" }}>
                <h5>Revise os dados e envie para os gestores</h5>
                <br />
                <Table
                  size="small"
                  bordered
                  dataSource={usersLideres}
                  columns={[
                    {
                      title: "Nome",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Cargo",
                      dataIndex: "cargo",
                      key: "cargo",
                    },
                    {
                      title: "E-mail",
                      dataIndex: "email",
                      key: "email",
                    },
                    {
                      title: "Telefone",
                      dataIndex: "telefone",
                      key: "telefone",
                    },
                    {
                      title: "Ações",
                      dataIndex: "_id",
                      key: "_id",
                      render: (_, record) => (
                        <Tooltip
                          title={
                            record.envios?.find(
                              (envio: any) =>
                                envio.subject ===
                                "Seusdados - Bem-vindo à Plataforma Workday"
                            )
                              ? "Enviar novamente - Último envio: " +
                                moment(
                                  new Date(
                                    record.envios?.find(
                                      (envio: any) =>
                                        envio.subject ===
                                        "Seusdados - Bem-vindo à Plataforma Workday"
                                    ).created_at
                                  )
                                ).format("DD/MM/YYYY HH:mm")
                              : "Enviar primeiro acesso"
                          }
                        >
                          <Button
                            icon={
                              record.envios?.find(
                                (envio: any) =>
                                  envio.subject ===
                                  "Seusdados - Bem-vindo à Plataforma Workday"
                              ) ? (
                                <CheckOutlined />
                              ) : (
                                <MailOutlined />
                              )
                            }
                            style={{
                              backgroundColor: record.envios?.find(
                                (envio: any) =>
                                  envio.subject ===
                                  "Seusdados - Bem-vindo à Plataforma Workday"
                              )
                                ? "#008C76"
                                : "",
                            }}
                            size="small"
                            type="primary"
                            onClick={() => {
                              postPrimeiroAcesso(record);
                            }}
                          >
                            {record.envios?.find(
                              (envio: any) =>
                                envio.subject ===
                                "Seusdados - Bem-vindo à Plataforma Workday"
                            )
                              ? "Enviado"
                              : "Enviar"}
                          </Button>
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              </div>
              <div
                style={{ width: "70%" }}
                className={
                  concluirStep2
                    ? "mapeamentos_steps triangulo"
                    : "mapeamentos_steps--hidden"
                }
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleFilled
                      style={{ fontSize: "80px", color: "#32E6BE" }}
                    />
                    <br />
                    <h2>Etapa concluída</h2>
                    <br />
                    <Button
                      onClick={() => setShowQuestionario(true)}
                      type="text"
                      style={{ color: "#008C76" }}
                    >
                      Ir para questionário preliminar <RightOutlined />
                    </Button>
                    {moduloMapeamento.currentUserHasLiderPermission && (
                      <>
                        <p
                          style={{
                            marginTop: "0.5rem",
                            marginBottom: "0.5rem",
                          }}
                        >
                          ou
                        </p>
                        <Button
                          onClick={() =>
                            moduloMapeamento.setActiveMapeamentoTab("3")
                          }
                          // type="text"
                          // style={{ color: "#008C76" }}
                        >
                          Definir titulares de dados
                          <RightOutlined />
                        </Button>
                      </>
                    )}
                  </div>
                  <br />
                  <div className="d-flex j-b">
                    <Button
                      icon={<LeftOutlined />}
                      type="text"
                      onClick={() => setStep(1)}
                      style={{ color: "#A3A3A3" }}
                    >
                      Etapa anterior
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Alert
          message="Nenhuma área cadastrada para preenchimento"
          showIcon
          type="warning"
        />
      )}

      <Modal
        open={showModalStep1}
        title=""
        onOk={() => setShowModalStep1(false)}
        onCancel={() => setShowModalStep1(false)}
        footer={[]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <br />

          <CheckCircleFilled style={{ fontSize: "80px", color: "#32E6BE" }} />
          <br />
          <h2>Etapa concluída</h2>
          <br />
          <br />

          <Button
            onClick={() => {
              setStep(1);
              setShowModalStep1(false);
            }}
            type="text"
            style={{ color: "#008C76" }}
          >
            Ir para próxima etapa <RightOutlined />
          </Button>
          <br />
          <br />
        </div>
      </Modal>
    </div>
  );
};

export default Mapeamento;

import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Form, Button, Checkbox, Input, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import Logo from "../../../assets/images/logo_white_background.png";
import InputMask from "react-input-mask";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";

const Meusdados = () => {
  const { postFornecedor, setFornecedores } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      try {
        postFornecedor(
          {
            lead: true,
            cliente_id: "656de00ca80c7c99f88fd1fb",
            nome: values.nome,
            cnpj: values.cnpj,
            email: values.email,
            telefone: values.celular,
            representante: values.representante,
            type: "novo",
          },
          null,
          "656de00ca80c7c99f88fd1fb"
        )
          .then((r) => {
            setFornecedores([r.fornecedor]);
            navigate(r.navigateLink);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        message.error("CNPJ inválido");
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao validar CNPJ", error);
    }
  };

  return (
    <div className="meusdados">
      <div className="meusdados__bgblock">
        <img src={Logo} alt="SeusDados" className="meusdados_logo" />
        <p>
          Receba agora um relatório detalhado sobre a maturidade em proteção de
          dados e segurança da sua empresa
        </p>
      </div>
      <div className="meusdados__formContainer">
        <h1>Preencha o formulário</h1>
        <Form
          className="meusdados_form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {/* <Form.Item
            label="Nome empresa"
            name="nome_empresa"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item> */}
          <Form.Item
            label="Razão social"
            name="nome"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Seu nome" name="representante">
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item label="Celular" name="celular">
            <InputMask mask="+99 99999999999" maskChar="" defaultValue="+55">
              <Input size="large" style={{ width: "100%" }} />
            </InputMask>
          </Form.Item>
          {/* <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <InputMask type="text" mask="99.999.999/9999-99">
              <Input size="large" />
            </InputMask>
          </Form.Item> */}
          <Form.Item
            name="concordo"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Checkbox>
              Eu concordo em receber comunicações e aceito os{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/Termos%20e%20Condic%CC%A7o%CC%83es%20de%20Uso%20-%20Plataforma%20Workday%20Seusdados%20-%20AC%CC%A7A%CC%83O%20COM%20FORNECEDORES.pdf"
              >
                Termos de Uso
              </a>{" "}
              e{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/AVISO%20DE%20PRIVACIDADE%20-%20Workday.pdf"
              >
                Aviso de Privacidade
              </a>
              .
            </Checkbox>
          </Form.Item>

          <Form.Item
            style={{
              marginTop: "5rem",
            }}
          >
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Avançar <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Meusdados;

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import { Alert, Button, Checkbox } from "antd";
import background from "../../../../../assets/images/background_pattern_rosa.png";

import { ArrowRightOutlined } from "@ant-design/icons";

const Introducao2 = () => {
  const navigate = useNavigate();

  const [checkbox, setCheckbox] = useState(false);

  const params = useParams();
  return (
    <div
      className="intro-2"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <h1>O que é a LGPD e por que preciso responder ao questionário?</h1>
      <br />
      <h3>
        A Lei Geral de Proteção de Dados (LGPD) é uma legislação que disciplina
        e orienta empresas na proteção dos dados pessoais que utilizam, com o
        objetivo de coibir abusos e práticas que possam ter um impacto
        indesejado na vida das pessoas, seja em ambientes digitais ou físicos.
        <br /> A LGPD impõe que as EMPRESAS garantam que seus parceiros de
        negócio estejam em grau satisfatório de conformidade com a Lei,
        assegurando a privacidade e proteção dos dados pessoais para mitigar
        possíveis riscos e vulnerabilidades.
        <br />
        Assim, para preservar a reputação dos parceiros de negócio e cumprir o
        papel de diligência e obediência à Lei, a Seusdados, na qualidade de
        Encarregada de Proteção de Dados (DPO), elaborou o presente
        questionário, visando obter informações sobre o grau de conformidade.
        <br />
        <br /> No caso de dúvidas no preenchimento, entre em contato através do
        (11) 4040-5552 ou 
        <a
          rel="noreferrer"
          style={{ color: "#1677ff" }}
          target="_blank"
          href="mailto:comunicacao@seusdados.com"
        >
          comunicacao@seusdados.com
        </a>
        . 
      </h3>
      <br />
      <Alert
        type={checkbox ? "success" : "info"}
        style={{ width: "100%", maxWidth: "900px" }}
        message={
          <Checkbox
            style={{ fontSize: "1rem", padding: "0 1rem" }}
            onChange={(v) => setCheckbox(v.target.checked)}
          >
            Eu concordo em receber comunicações e aceito os{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/Termos%20e%20Condic%CC%A7o%CC%83es%20de%20Uso%20-%20Plataforma%20Workday%20Seusdados%20-%20AC%CC%A7A%CC%83O%20COM%20FORNECEDORES.pdf"
            >
              Termos de Uso
            </a>{" "}
            e{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/AVISO%20DE%20PRIVACIDADE%20-%20Workday.pdf"
            >
              Aviso de Privacidade
            </a>
            .
          </Checkbox>
        }
      />

      {checkbox && (
        <Button
          icon={<ArrowRightOutlined />}
          className="btn-next"
          onClick={() =>
            navigate(
              `/formulario/4/${params.id}/${params.fornecedorid}/${params.questionarioid}`
            )
          }
        >
          Avançar
        </Button>
      )}
    </div>
  );
};

export default Introducao2;

import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio, Table } from "antd";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import BackHeader from "../../../BackHeader/BackHeader";

// Registrar os plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const InformacoesAgendamentos: React.FC = () => {
  const { obterReunioesPorCppd } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const { cppdId } = useParams<{ cppdId: string }>();
  const [intervalo, setIntervalo] = useState<string | undefined>(undefined);
  const [diaSemana, setDiaSemana] = useState<string>("Não definido");
  const [dataPrimeiraReuniao, setDataPrimeiraReuniao] = useState<
    string | undefined
  >(undefined);
  const [horarioPadrao, setHorarioPadrao] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchReunioes = async () => {
      try {
        const data = await obterReunioesPorCppd(cppdId!);
        const reunioes = data.reunioes;

        if (reunioes.length > 0) {
          const primeiraReuniao = reunioes[0];
          const intervaloDias = primeiraReuniao.intervalo;

          // Determina o intervalo
          switch (intervaloDias) {
            case 15:
              setIntervalo("Quinzenal");
              break;
            case 30:
              setIntervalo("Mensal");
              break;
            case 60:
              setIntervalo("Bimestral");
              break;
            case 90:
              setIntervalo("Trimestral");
              break;
            default:
              setIntervalo("Não definido");
          }

          // Ajusta o horário para o fuso horário correto na exibição
          const formattedDate = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY");
          setDataPrimeiraReuniao(formattedDate);

          const horario = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("HH:mm");
          setHorarioPadrao(horario || "Não definido");

          // Determina o dia da semana
          const diaSemana = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("dddd");
          setDiaSemana(diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1)); // Capitaliza a primeira letra
        }
      } catch (error) {
        console.error("Erro ao obter reuniões:", error);
      }
    };

    if (cppdId) {
      fetchReunioes();
    } else {
      console.log("cliente_id não definido.");
    }
  }, [obterReunioesPorCppd, cppdId]);

  return (
    <div className="my-8">
      <BackHeader title="CPPD - Reuniões" prev />

      <h5>Informações dos agendamentos</h5>
      <br />
      <Table
        pagination={false}
        dataSource={[
          { intervalo, diaSemana, dataPrimeiraReuniao, horarioPadrao },
        ]}
        columns={[
          {
            title: "Regimento",
            dataIndex: "intervalo",
            key: "intervalo",
          },
          {
            title: "Dia da semana",
            dataIndex: "diaSemana",
            key: "diaSemana",
          },
          {
            title: "Data da primeira reunião",
            dataIndex: "dataPrimeiraReuniao",
            key: "dataPrimeiraReuniao",
          },
          {
            title: "Horário padrão",
            dataIndex: "horarioPadrao",
            key: "horarioPadrao",
          },
        ]}
      />
    </div>
  );
};

export default InformacoesAgendamentos;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  message,
  Space,
  Steps,
  Table,
  theme,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ReuniaoStatus from "./ReuniaoStatus";
import { Reuniao } from "./types";
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import NewUser from "../../../../components/NewUser/NewUser";

const { Step } = Steps;
const { Search } = Input;

interface ReuniaoPanelProps {
  reuniao: Reuniao;
  membroId: string;
  cppdId: string;
  setIsAtaModalVisible: (value: boolean) => void;
  setIsEditLinkModalVisible: (value: boolean) => void;
  setIsEnviarAtaModalVisible: (value: boolean) => void;
  setCurrentReuniaoId: (id: string | null) => void;
  setEditDateModalVisible: (value: boolean) => void;
  handleUpdateStatus: (id: string, status: string) => Promise<void>;
  setIsPautasModalVisible: (value: boolean) => void;
}
const { useToken } = theme;

const statusMapping: { [key: string]: number } = {
  Pendente: 0,
  "Em andamento": 1, // Reunião em andamento
  Realizada: 2, // Reunião realizada
};

const ReuniaoPanel: React.FC<ReuniaoPanelProps> = ({
  reuniao,
  setIsAtaModalVisible,
  setIsEditLinkModalVisible,
  setIsEnviarAtaModalVisible,
  setCurrentReuniaoId,
  setEditDateModalVisible,
  handleUpdateStatus,
  setIsPautasModalVisible,
  membroId,
}) => {
  const navigate = useNavigate();
  const { usuarios, currentCliente, atualizarReuniaoPorId } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [searchValue, setSearchValue] = useState("");
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [listaMembros, setListaMembros] = useState<any>(reuniao.membros_invite);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };
  const { token } = useToken();
  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "300px",
    maxHeight: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  };
  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  // Mapeia o status atual da reunião para a etapa correspondente
  const currentStep = statusMapping[reuniao.status] || 0;

  // Lógica para verificar o status da reunião e atualizar conforme necessário
  useEffect(() => {
    const agora = dayjs(); // Obtém o horário atual
    const inicioReuniao = dayjs(reuniao.data_agendamento); // Data e hora de início da reunião

    // Verifica se a reunião não está finalizada e se não está "Em Andamento"
    if (
      reuniao.status.toLowerCase() !== "realizada" &&
      reuniao.status.toLowerCase() !== "em andamento"
    ) {
      // Se a data e hora atuais coincidirem com o início da reunião, marca como "Em Andamento"
      if (agora.isSame(inicioReuniao, "minute")) {
        handleUpdateStatus(reuniao._id, "Em Andamento");
      }
    }
  }, [reuniao, handleUpdateStatus]);

  const handleOpenAtaModal = () => {
    setCurrentReuniaoId(reuniao._id); // Atualiza o ID da reunião atual
    setTimeout(() => {
      setIsAtaModalVisible(true); // Abre o modal após um pequeno delay
    }, 0);
  };

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  const handleUpdateMembrosInvite = async (membros: string[]) => {
    setLoading(true);
    try {
      setListaMembros(membros);

      await atualizarReuniaoPorId(reuniao.cppd_id!, reuniao._id, {
        membros_invite: membros,
      });

      message.success("Lista de membros atualizada com sucesso!");
    } catch (error) {
      message.error("Erro ao atualizar lista.");
    }
    setLoading(false);
  };
  return (
    <div className="p-4 space-y-5 text-sm">
      <Steps current={currentStep} size="small" className="mb-4">
        <Step title="Envio da reunião" status="finish" />
        <Step title="Convocação" status={"finish"} />
        <Step title="Reunião realizada" status={"finish"} />
      </Steps>
      <br />
      <div className="d-flex j-b">
        <div style={{ width: "800px" }}>
          <h5>Informações da reunião</h5>
          <br />
          <p>
            Data da reunião:{" "}
            {dayjs(reuniao.data_agendamento).format("HH:mm - DD/MM/YYYY")}
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setCurrentReuniaoId(reuniao._id);
                setEditDateModalVisible(true);
              }}
            >
              Editar
            </Button>
          </p>
          <ReuniaoStatus
            reuniaoId={reuniao._id}
            status={reuniao.status}
            onUpdateStatus={handleUpdateStatus}
          />
          <div className="flex items-center">
            <p>
              Link do agendamento:{" "}
              <Button
                type="link"
                href={`${reuniao.link}`}
                target="_blank"
                icon={<ExportOutlined />}
                rel="noopener noreferrer"
              >
                {reuniao.link!.length > 13
                  ? `${reuniao.link?.substring(0, 28)}...`
                  : reuniao.link}
              </Button>
            </p>
            {/* <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setCurrentReuniaoId(reuniao._id);
            setIsEditLinkModalVisible(true);
          }}
        >
          Editar
        </Button> */}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <h5>Membros da reunião</h5>
          <br />
          <Table
            pagination={false}
            size="small"
            dataSource={listaMembros?.map((membro: any) =>
              usuarios?.find((u: any) => u._id === membro)
            )}
            columns={[
              {
                title: "Nome",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
              },
              {
                title: "Ações",
                dataIndex: "_id",
                key: "_id",
                render: (_id: string) => (
                  <Button
                    icon={<DeleteOutlined />}
                    type="link"
                    style={{ color: "red" }}
                    onClick={(v: any) => {
                      handleUpdateMembrosInvite([
                        ...listaMembros.filter((u: string) => u !== _id),
                      ]);
                    }}
                  >
                    Excluir
                  </Button>
                ),
              },
            ]}
          />
          <br />
          <Tooltip title="Adicionar mais um usuário ao convite da reunião">
            <Dropdown
              placement="bottom"
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "1",
                    type: "group",
                    label: "Membros do comitê",
                    children: usuarios
                      ?.filter((usr: any) =>
                        usr.grupos
                          ?.map((g: any) => g._id)
                          .some((grupo: string) =>
                            gruposDoComite.includes(grupo)
                          )
                      )
                      ?.filter(
                        (usr: any) => usr.cliente_id === currentCliente._id
                      )
                      ?.filter((usr: any) =>
                        usr.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      )
                      ?.filter((usr: any) => !listaMembros.includes(usr._id))
                      ?.map((usr: any) => {
                        return {
                          key: usr._id,
                          label: usr.name,
                          onClick: (v: any) => {
                            handleUpdateMembrosInvite([
                              ...listaMembros,
                              usr._id,
                            ]);
                          },
                        };
                      }),
                  },
                  {
                    key: "2",
                    type: "group",
                    label: "Consultores",
                    children: usuarios
                      ?.filter((usr: any) =>
                        usr.grupos.find(
                          (g: any) =>
                            g.name === "assinante" || g.name === "seusdados"
                        )
                      )
                      ?.filter((usr: any) =>
                        usr.name
                          ?.toLowerCase()
                          .includes(searchValue?.toLowerCase())
                      )
                      ?.filter((usr: any) => !listaMembros.includes(usr._id))
                      ?.map((usr: any) => {
                        return {
                          key: usr._id,
                          label: usr.name,
                          onClick: (v: any) => {
                            handleUpdateMembrosInvite([
                              ...listaMembros,
                              usr._id,
                            ]);
                          },
                        };
                      }),
                  },
                ],
              }}
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  <Search
                    style={{
                      margin: "10px 18px",
                      width: "264px",
                    }}
                    placeholder="Buscar"
                    onSearch={handleSearch}
                    enterButton
                  />
                  <Divider style={{ margin: 0 }} />
                  {React.cloneElement(menu as React.ReactElement, {
                    style: menuStyle,
                  })}
                  <Divider style={{ margin: 0 }} />
                  <Space
                    style={{
                      padding: 8,
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<UserAddOutlined />}
                      onClick={() => setShowModalNewUser(true)}
                    >
                      Novo usuário
                    </Button>
                  </Space>
                </div>
              )}
            >
              <Button loading={loading} icon={<UserAddOutlined />}>
                Adicionar membro
              </Button>
            </Dropdown>
          </Tooltip>
        </div>
      </div>
      <div className="mt-4">
        <Button
          type="primary"
          className="mr-2"
          onClick={() => {
            setCurrentReuniaoId(reuniao._id);
            setIsPautasModalVisible(true);
          }}
        >
          Pautas
        </Button>
        <Button
          type="primary"
          className="mr-2"
          onClick={() => {
            navigate(`/cppd/${reuniao.cppd_id}/reunioes/dia-da-reuniao`, {
              state: { membroId, reuniao },
            });
          }}
        >
          Dia da reunião
        </Button>
        <Button type="primary" onClick={handleOpenAtaModal}>
          ATA
        </Button>
      </div>
      <NewUser
        type="grupo"
        showModal={showModalNewUser}
        setShowModal={setShowModalNewUser}
      />
    </div>
  );
};

export default ReuniaoPanel;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Collapse, Tag, message, Button } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import DadosEmpresa from "../../dadosEmpresa/Dados-empresa";
import InformacoesAgendamentos from "./InformacoesAgendamento";
import AtaModal from "./Modals/AtaModal";
import EditDateModal from "./Modals/EditDateModal";
import EditLinkModal from "./Modals/EditLinkModal";
import EnviarAtaModal from "./Modals/EnviarAtaModal";
import ReuniaoPanel from "./ReuniaoPanel";
import PautasModal from "./Modals/PautasModal";
import CriarReuniaoExtraModal from "./Modals/reuniaoExtraModal";

import { Reuniao } from "./types";
import dayjs from "dayjs";

const { Panel } = Collapse;

const ListaDeReunioes: React.FC = () => {
  const { cppdId } = useParams<{ cppdId?: string }>();

  const location = useLocation();
  const {
    obterReunioesPorCppd,
    atualizarReuniaoPorId,
    currentCliente,
    criarReuniaoImediataTeams,
    usuarios,
    obterCppdPorId,
  } = useContext(GlobalContext) as GlobalContextType;

  const navigate = useNavigate();

  const [reunioes, setReunioes] = useState<Reuniao[]>([]);
  const [cliente, setCliente] = useState<any>(null);
  const [cliente_id, setClienteId] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [isAtaModalVisible, setIsAtaModalVisible] = useState(false);
  const [isEditLinkModalVisible, setIsEditLinkModalVisible] = useState(false);
  const [isEnviarAtaModalVisible, setIsEnviarAtaModalVisible] = useState(false);
  const [editDateModalVisible, setEditDateModalVisible] = useState(false);
  const [isPautasModalVisible, setIsPautasModalVisible] = useState(false);
  const [currentReuniaoId, setCurrentReuniaoId] = useState<string | null>(null);
  const [selectedReuniao, setSelectedReuniao] = useState<Reuniao | undefined>(
    undefined
  );
  const [isCriarReuniaoModalVisible, setIsCriarReuniaoModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false); // Adiciona estado de loading
  const panelRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    !currentCliente?._id && navigate("/cppd");
  }, []);

  useEffect(() => {
    const fetchCppd = async () => {
      if (cppdId) {
        const cppd = await obterCppdPorId(cppdId);
        if (cppd) {
          setClienteId(cppd.cliente);
        } else {
          console.log("No CPPD found for ID:", cppdId);
        }
      }
    };
    fetchCppd();
  }, [cppdId, obterCppdPorId]);

  useEffect(() => {
    const fetchReunioes = async () => {
      try {
        if (cppdId) {
          const response = await obterReunioesPorCppd(cppdId);
          setReunioes(response.reunioes);
          setCliente(response.cliente);

          const activeReuniaoId = location.state?.activeReuniaoId;
          if (activeReuniaoId) {
            setActiveKey([activeReuniaoId]);

            setTimeout(() => {
              panelRefs.current[activeReuniaoId]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 300);
          }
        }
      } catch (error) {
        console.error("Erro ao obter reuniões:", error);
      }
    };

    fetchReunioes();
  }, [cppdId, obterReunioesPorCppd, location.state]);

  useEffect(() => {
    if (currentReuniaoId) {
      const reuniao = reunioes.find((r) => r._id === currentReuniaoId);
      setSelectedReuniao(reuniao || undefined);
    }
  }, [currentReuniaoId, reunioes]);

  const handlePanelChange = (key: string | string[]) => {
    setActiveKey(typeof key === "string" ? [key] : key);
  };

  const getPanelStyle = (status: string) => {
    switch (status) {
      case "Realizada":
        return {
          backgroundColor: "#b3ffc7",
          borderColor: "#f6ffed",
        };
      case "Em andamento":
        return {
          backgroundColor: "#9cc3ff",
          borderColor: "#e6f7ff",
        };
      case "Pendente":
      default:
        return {
          backgroundColor: "#e2e0e0",
          borderColor: "#d9d9d9",
        };
    }
  };

  const handleUpdateStatus = async (reuniaoId: string, newStatus: string) => {
    try {
      await atualizarReuniaoPorId(cppdId!, reuniaoId, {
        status: newStatus,
      });
      const updatedReunioes = reunioes.map((reuniao) =>
        reuniao._id === reuniaoId ? { ...reuniao, status: newStatus } : reuniao
      );
      setReunioes(updatedReunioes);
      console.log(updatedReunioes, "visão do update");
    } catch (error) {
      console.error("Erro ao atualizar status:", error);
    }
  };

  const handleUpdateDate = async (reuniaoId: string, date: dayjs.Dayjs) => {
    try {
      await atualizarReuniaoPorId(cppdId!, reuniaoId, {
        data_agendamento: date,
      });
      const updatedReunioes = reunioes.map((reuniao) =>
        reuniao._id === reuniaoId
          ? { ...reuniao, data_agendamento: date.toISOString() }
          : reuniao
      );
      setReunioes(updatedReunioes);
      message.success("Data da reunião atualizada com sucesso!");
    } catch (error) {
      message.error("Erro ao atualizar a data da reunião.");
    }
  };

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  const membros = usuarios?.filter(
    (usr: any) =>
      usr.cliente_id === cliente_id &&
      usr.grupos
        ?.map((g: any) => g._id)
        .some((grupo: string) => gruposDoComite.includes(grupo))
  );

  const handleCriarReuniaoExtra = async () => {
    setLoading(true); // Ativa o loading no botão
    try {
      if (cliente_id) {
        const response = await criarReuniaoImediataTeams(cliente_id, membros);

        // Verifica se o objeto meeting existe e se o joinUrl está presente
        const meetingLink = response?.meeting?.onlineMeeting?.joinUrl;

        if (meetingLink) {
          // Abre a nova aba com o link da reunião
          window.open(meetingLink, "_blank");
          message.success("Reunião Extraordinária criada com sucesso!");
        } else {
          message.error("Nenhum link de reunião foi retornado.");
        }

        setIsCriarReuniaoModalVisible(false);
      }
    } catch (error) {
      message.error("Erro ao criar a reunião extraordinária.");
      console.error("Erro ao criar reunião extraordinária:", error);
    } finally {
      setLoading(false); // Desativa o loading no botão
    }
  };

  return (
    <div className="container mx-auto p-4">
      {cliente && <DadosEmpresa cppdId={cppdId!} clienteData={cliente} />}

      <InformacoesAgendamentos />

      <div>
        <Button
          type="primary"
          className="mb-10"
          onClick={() => setIsCriarReuniaoModalVisible(true)}
          loading={loading}
        >
          Criar reunião extraordinária
        </Button>
      </div>

      <Collapse activeKey={activeKey} onChange={handlePanelChange}>
        {reunioes.map((reuniao) => (
          <Panel
            header={
              <div className="flex items-center font-extrabold">
                <span>
                  R{reuniao.ordem_reuniao}
                  {reuniao.subordem_reuniao
                    ? "." + reuniao.subordem_reuniao
                    : ""}
                </span>
                <Tag color="blue" className="ml-2">
                  {reuniao?.status}
                </Tag>
              </div>
            }
            key={reuniao._id}
            ref={(el) => (panelRefs.current[reuniao._id] = el)}
            style={getPanelStyle(reuniao.status)}
            className="min-h-[100px] h-full"
          >
            <ReuniaoPanel
              cppdId={reuniao.cppd_id}
              membroId={cliente._id}
              reuniao={reuniao}
              setIsAtaModalVisible={setIsAtaModalVisible}
              setIsEditLinkModalVisible={setIsEditLinkModalVisible}
              setIsEnviarAtaModalVisible={setIsEnviarAtaModalVisible}
              setCurrentReuniaoId={setCurrentReuniaoId}
              setEditDateModalVisible={setEditDateModalVisible}
              handleUpdateStatus={handleUpdateStatus}
              setIsPautasModalVisible={setIsPautasModalVisible}
            />
          </Panel>
        ))}
      </Collapse>
      {currentReuniaoId && (
        <PautasModal
          reuniaoId={currentReuniaoId}
          isVisible={isPautasModalVisible}
          onClose={() => {
            setIsPautasModalVisible(false);
            setCurrentReuniaoId(null);
          }}
        />
      )}

      <AtaModal
        cliente={cliente}
        reuniao={selectedReuniao}
        isVisible={isAtaModalVisible}
        setIsVisible={setIsAtaModalVisible}
      />

      <EditDateModal
        isVisible={editDateModalVisible}
        setIsVisible={setEditDateModalVisible}
        currentReuniaoId={currentReuniaoId}
        onUpdateDate={handleUpdateDate}
      />

      <EditLinkModal
        isVisible={isEditLinkModalVisible}
        setIsVisible={setIsEditLinkModalVisible}
        currentReuniaoId={currentReuniaoId}
      />

      <EnviarAtaModal
        isVisible={isEnviarAtaModalVisible}
        setIsVisible={setIsEnviarAtaModalVisible}
      />

      <CriarReuniaoExtraModal
        isVisible={isCriarReuniaoModalVisible}
        onClose={() => setIsCriarReuniaoModalVisible(false)}
        onCreate={handleCriarReuniaoExtra}
        loading={loading} // Passa o estado de loading para o modal
      />
    </div>
  );
};

export default ListaDeReunioes;

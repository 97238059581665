import GlobalContextProvider from "./context/globalContext";
import { Route, Routes, useLocation, Navigate } from "react-router";
import Clientes from "./pages/Modulos/Clientes/Clientes";
import "./App.css";
import Usuarios from "./pages/Modulos/Usuarios/Usuarios";
import EditUsers from "./pages/Apps/PAM/EditUsers/EditUsers";
import EditClientes from "./pages/Modulos/EditCliente/EditCliente";
import Areas from "./pages/Apps/Questionarios/Areas/Areas";
import EditCapitulo from "./pages/Apps/Questionarios/EditCapitulo/EditCapitulo";
import Book from "./pages/Apps/PAM/Book/Book";
import Analises from "./pages/Apps/PAM/Analises/Analises";
import EditAnalise from "./pages/Apps/PAM/EditAnalise/EditAnalise";
import QuestionarioFornecedor from "./pages/Apps/AcaoFornecedores/Questionario/Questionario/Questionario";
import FormRespostas from "./pages/Apps/PAM/FormRespostas/FormRespostas";
import ClientesFornecedor from "./pages/Apps/AcaoFornecedores/ClientesFornecedor/ClientesFornecedor";
import FornecedorClientesFormulario from "./pages/Apps/AcaoFornecedores/Fornecedores/Fornecedores";
import FormularioRespostaFornecedor from "./pages/Apps/AcaoFornecedores/EditFornecedores/EditFornecedores";
import RespostaFornecedor from "./pages/Apps/AcaoFornecedores/Respostas/Respostas";
import { Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./components/authentication-guard";
import MainLayout from "./components/MainLayout/MainLayout";
import AnaliseFornecedor from "./pages/Apps/AcaoFornecedores/AnaliseFornecedor/AnaliseFornecedor";
import Introducao1 from "./pages/Apps/AcaoFornecedores/Questionario/Introducao1/Introducao1";
import Introducao2 from "./pages/Apps/AcaoFornecedores/Questionario/Introducao2/Introducao2";
import Introducao3 from "./pages/Apps/AcaoFornecedores/Questionario/Introducao3/Introducao3";
import Introducao4 from "./pages/Apps/AcaoFornecedores/Questionario/Introducao4/Introducao4";
import DashboardFornecedor from "./pages/Apps/AcaoFornecedores/Dashboard/Dashboard";
import Templates from "./pages/Apps/Questionarios/Templates/Templates";
import EditTemplate from "./pages/Apps/Questionarios/EditTemplate/EditTemplate";
import ViewQuestionarios from "./pages/Apps/Questionarios/ViewQuestionarios/ViewQuestionarios";
import Responder from "./pages/Apps/Questionarios/Responder/Responder";
import AnaliseMultipla from "./pages/Apps/Questionarios/AnaliseMultipla/AnaliseMultipla";
import Capitulos from "./pages/Apps/Questionarios/Capitulos/Capitulos";
import Perguntas from "./pages/Apps/Questionarios/Perguntas/Perguntas";
import TaskList from "./components/Tarefas/TaskList/TaskList";
import TemplatesTarefas from "./pages/Apps/Tarefas/Templates/TemplatesTarefas";
import EditTemplateTask from "./pages/Apps/Tarefas/EditTemplateTask/EditTemplateTask";
import Meusdados from "./pages/Modulos/Meusdados/Meusdados";
import PlanoAcaoCVE from "./pages/Apps/CSA/PlanoAcaoCVE/PlanoAcaoCVE";
import PlanoAcaoQuestCSA from "./pages/Apps/CSA/PlanoAcaoQuestCSA/PlanoAcaoQuestCSA";
import OwaspCliente from "./pages/Apps/CSA/OwaspCliente/OwaspCliente";
import Fichas from "./pages/Modulos/Fichas/Fichas";
import PlanoAcaoConsultor from "./pages/Apps/AcaoFornecedores/PlanoAcaoConsultor/PlanoAcaoConsultor";
import FeedbackList from "./pages/Modulos/Feedbacks/Feedbacks";
import Diagnosticos from "./pages/Modulos/Comercial/Diagnosticos";
import Leads from "./pages/Modulos/Comercial/Leads";

import { ConfigProvider } from "antd";
import Mapeamentos from "./pages/Apps/Mapeamentos/Mapeamentos";
import QuestionariosCPPD from "./pages/Modulos/CPPD/questionariosCPPD";
import Governanca from "./pages/Modulos/Meusdados/Governanca/Governanca";
import ListQuestinarios from "./pages/Apps/Questionarios/ListQuestionarios/ListQuestionarios";
import OWASP from "./pages/Apps/CSA/OWASP/OWASP";
import TarefasCliente from "./pages/Apps/Tarefas/TarefasCliente/TarefasCliente";
import Liberacao from "./pages/Modulos/Liberacao/Liberacao";
import DashboardInicial from "./pages/Modulos/DashboardInicial/DashboardInicial";
import CPPD from "./pages/Modulos/CPPD/CPPD";
import ListaDeReunioes from "./components/CPPD/ComitePrivacidade/ListaReunioes/ListaReunioes";
import DiaDaReuniao from "./components/CPPD/ComitePrivacidade/ListaReunioes/DiadaReuniaoComponent/DiadaReuniao";
import ModeloPautas from "./pages/Modulos/CPPD/ModeloPautas/modeloPautas";
export default function App() {
  const location = useLocation();

  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="spinContainer">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <GlobalContextProvider>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#5f29cc",
            borderRadius: 20,
            colorBgBase: "#fff",
            colorError: "#ff4d4f",
            colorInfo: "#5f29cc",
            colorLink: "#5f29cc",
            colorSuccess: "#52c41a",
            colorTextBase: "#000",
            colorWarning: "#faad14",
            controlHeight: 32,
            fontFamily: "Inter",
            fontSize: 14,
            lineType: "solid",
            lineWidth: 1,
            sizeStep: 4,
          },
        }}
      >
        <Routes location={location}>
          <Route path="/" element={<Navigate to="/dashboardinicial" />} />
          <Route path="/meusdados" element={<Meusdados />} />
          <Route path="/avaliacao" element={<Meusdados />} />
          <Route path="/maturidadegov" element={<Governanca />} />

          {/* DASHBOARDINICIAL */}
          <Route element={<MainLayout />}>
            <Route
              path="dashboardinicial"
              element={<AuthenticationGuard component={DashboardInicial} />}
            />
            {/* CPPD */}
            <Route
              path="/cppd"
              element={<AuthenticationGuard component={CPPD} />}
            />
            <Route
              path="/cppd/:cppdId/reunioes"
              element={<AuthenticationGuard component={ListaDeReunioes} />}
            />
            <Route
              path="/cppd/:cppdId/reunioes/dia-da-reuniao"
              element={<AuthenticationGuard component={DiaDaReuniao} />}
            />
            <Route
              path="pautas"
              element={<AuthenticationGuard component={ModeloPautas} />}
            />
            <Route
              path="/dashboard"
              element={<AuthenticationGuard component={DashboardInicial} />}
            />
            <Route
              path="clientes"
              element={<AuthenticationGuard component={Clientes} />}
            />
            <Route
              path="fichas"
              element={<AuthenticationGuard component={Fichas} />}
            />
            <Route
              path="usuarios"
              element={<AuthenticationGuard component={Usuarios} />}
            />
            <Route
              path="feedbacks"
              element={<AuthenticationGuard component={FeedbackList} />}
            />
            <Route
              path="areas"
              element={<AuthenticationGuard component={Areas} />}
            />
            <Route
              path="csa"
              element={<AuthenticationGuard component={PlanoAcaoCVE} />}
            />
            <Route
              path="owasp"
              element={<AuthenticationGuard component={OwaspCliente} />}
            />
            <Route
              path="csaquest"
              element={<AuthenticationGuard component={PlanoAcaoQuestCSA} />}
            />
            <Route
              path="capitulos"
              element={<AuthenticationGuard component={Capitulos} />}
            />
            <Route
              path="perguntas"
              element={<AuthenticationGuard component={Perguntas} />}
            />
            <Route
              path="analise"
              element={<AuthenticationGuard component={Analises} />}
            />
            <Route
              path="book"
              element={<AuthenticationGuard component={Book} />}
            />
            <Route
              path="analise/questionarios/:clientid"
              element={<AuthenticationGuard component={ListQuestinarios} />}
            />
            <Route
              path="templates"
              element={<AuthenticationGuard component={Templates} />}
            />
            <Route
              path="tarefas"
              element={<AuthenticationGuard component={TaskList} />}
            />
            <Route
              path="templates/:templateid"
              element={<AuthenticationGuard component={EditTemplate} />}
            />
            <Route
              path="templates/novo"
              element={<AuthenticationGuard component={EditTemplate} />}
            />
            <Route
              path="analise/questionarios/:clientid/:qid"
              element={<AuthenticationGuard component={EditAnalise} />}
            />
            <Route
              path="modelostarefas"
              element={<AuthenticationGuard component={TemplatesTarefas} />}
            />
            <Route
              path="modelostarefas/novo"
              element={<AuthenticationGuard component={EditTemplateTask} />}
            />
            <Route
              path="modelostarefas/:templateid"
              element={<AuthenticationGuard component={EditTemplateTask} />}
            />
            <Route
              path="responder/:id/:noauthuserid"
              element={<FormRespostas />}
            />
            <Route
              path="responder/:token"
              element={<AuthenticationGuard component={Responder} />}
            />
            {/* responder sem login */}
            <Route path="responder/00/:token" element={<Responder />} />
            <Route
              path="usuarios/:userid"
              element={<AuthenticationGuard component={EditUsers} />}
            />
            <Route
              path="clientes/editar/:id/questionario/:qid"
              element={<AuthenticationGuard component={EditTemplate} />}
            />
            <Route
              path="clientes/editar/:id/ver/:qid"
              element={<AuthenticationGuard component={ViewQuestionarios} />}
            />
            <Route
              path="/analise/:qid/versao/:vid"
              element={<AuthenticationGuard component={AnaliseMultipla} />}
            />
            <Route
              path="clientes/editar/:id"
              element={<AuthenticationGuard component={EditClientes} />}
            />
            <Route
              path="clientes/novo"
              element={<AuthenticationGuard component={EditClientes} />}
            />
            <Route
              path="questionarioscppd"
              element={<AuthenticationGuard component={QuestionariosCPPD} />}
            />
            <Route
              path="areas/editar"
              element={<AuthenticationGuard component={EditCapitulo} />}
            />
            <Route
              path="diagnosticos"
              element={<AuthenticationGuard component={Diagnosticos} />}
            />
            <Route
              path="leads"
              element={<AuthenticationGuard component={Leads} />}
            />
            <Route
              path="capitulos/editar/:id"
              element={<AuthenticationGuard component={EditCapitulo} />}
            />
            <Route
              path="dashboardContratante/"
              element={<AuthenticationGuard component={DashboardFornecedor} />}
            />
            <Route
              path="mapeamentos"
              element={<AuthenticationGuard component={Mapeamentos} />}
            />
            <Route
              path="mapeamentos/:clienteid/:mapeamentoid"
              element={<AuthenticationGuard component={Mapeamentos} />}
            />
            <Route
              path="aplicacoes"
              element={<AuthenticationGuard component={ListQuestinarios} />}
            />
            <Route
              path="owasp"
              element={<AuthenticationGuard component={OWASP} />}
            />
            <Route
              path="resultadoscsa"
              element={<AuthenticationGuard component={TarefasCliente} />}
            />{" "}
            <Route
              path="liberacao"
              element={<AuthenticationGuard component={Liberacao} />}
            />
            {/* <Route
                <Route path="csa" element={<AuthenticationGuard component={PlanoAcaoCVE} />} />
                <Route path="owasp" element={<AuthenticationGuard component={OwaspCliente} />} />
                <Route path="csaquest" element={<AuthenticationGuard component={PlanoAcaoQuestCSA} />} />
                <Route path="capitulos" element={<AuthenticationGuard component={Capitulos} />} />
                <Route path="perguntas" element={<AuthenticationGuard component={Perguntas} />} />
                <Route path="analise" element={<AuthenticationGuard component={Analises} />} />
                <Route path="book" element={<AuthenticationGuard component={Book} />} />
                <Route path="analise/questionarios/:clientid" element={<AuthenticationGuard component={ListQuestinarios} />} />
                <Route path="templates" element={<AuthenticationGuard component={Templates} />} />
                <Route path="tarefas" element={<AuthenticationGuard component={TaskList} />} />
                <Route path="templates/:templateid" element={<AuthenticationGuard component={EditTemplate} />} />
                <Route path="templates/novo" element={<AuthenticationGuard component={EditTemplate} />} />
                <Route path="analise/questionarios/:clientid/:qid" element={<AuthenticationGuard component={EditAnalise} />} />
                <Route path="modelostarefas" element={<AuthenticationGuard component={TemplatesTarefas} />} />
                <Route path="modelostarefas/novo" element={<AuthenticationGuard component={EditTemplateTask} />} />
                <Route path="modelostarefas/:templateid" element={<AuthenticationGuard component={EditTemplateTask} />} />
                <Route path="responder/:id/:noauthuserid" element={<FormRespostas />} />
                <Route path="responder/:token" element={<Responder />} />
                <Route path="usuarios/:userid" element={<AuthenticationGuard component={EditUsers} />} />
                <Route path="clientes/editar/:id/questionario/:qid" element={<AuthenticationGuard component={EditTemplate} />} />
                <Route path="clientes/editar/:id/ver/:qid" element={<AuthenticationGuard component={ViewQuestionarios} />} />
                <Route path="/analise/:qid/versao/:vid" element={<AuthenticationGuard component={AnaliseMultipla} />} />
                <Route path="clientes/editar/:id" element={<AuthenticationGuard component={EditClientes} />} />
                <Route path="clientes/novo" element={<AuthenticationGuard component={EditClientes} />} />
                <Route path="areas/editar" element={<AuthenticationGuard component={EditCapitulo} />} />
                <Route path="capitulos/editar/:id" element={<AuthenticationGuard component={EditCapitulo} />} />
                <Route path="dashboardContratante/" element={<AuthenticationGuard component={DashboardFornecedor} />} />
                {/* <Route
                path="dashboardFornecedor/"
                element={
                  <AuthenticationGuard component={DashboardFornecedor} />
                }
              /> */}
            <Route path="formulario" element={<QuestionarioFornecedor />} />
            {/* <Route
                path="dashboardContratante/"
                element={
                  <AuthenticationGuard component={DashboardContratante} />
                }
              /> */}
            {/* <Route
                path="dashboardFornecedor/"
                element={
                  <AuthenticationGuard component={DashboardFornecedor} />
                }
              /> */}
            <Route
              path="/formulario/1/:id/:fornecedorid/:questionarioid"
              element={<Introducao1 />}
            />
            <Route
              path="/formulario/2/:id/:fornecedorid/:questionarioid"
              element={<Introducao2 />}
            />
            <Route
              path="/formulario/3/:id/:fornecedorid/:questionarioid"
              element={<Introducao3 />}
            />
            <Route
              path="/formulario/4/:id/:fornecedorid/:questionarioid"
              element={<QuestionarioFornecedor />}
            />
            <Route
              path="/formulario/5/:id/:fornecedorid/:questionarioid"
              element={<Introducao4 />}
            />
            <Route
              path="/planoacao/parceiros/:clienteid"
              element={<PlanoAcaoConsultor />}
            />
            <Route
              path="/analisefornecedor/:clienteid/:fornecedorid/:questionarioid"
              element={<AnaliseFornecedor />}
            />
            <Route
              path="/formularioresposta/:id/:fornecedorid"
              element={
                <AuthenticationGuard component={FormularioRespostaFornecedor} />
              }
            />
            <Route
              path="/resposta/:id/:fornecedorid/:questionarioid"
              element={<AuthenticationGuard component={RespostaFornecedor} />}
            />
            <Route
              path="clientesFornecedor"
              element={<AuthenticationGuard component={ClientesFornecedor} />}
            />
            <Route
              path="clientesFornecedor/fornecedor/:id"
              element={
                <AuthenticationGuard component={FornecedorClientesFormulario} />
              }
            />
          </Route>

          <Route path="*" element={<h1>Endereço não encontrado</h1>} />
        </Routes>
      </ConfigProvider>
    </GlobalContextProvider>
  );
}

import { Button, Radio, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import { Questionario } from "../../../../../context/mapeamentoContext";
import "./styles.css";
import { ExclamationCircleFilled } from "@ant-design/icons";

interface QuestionarioPreliminarProps {
  area: string;
  mapeamento: Questionario;
}
const QuestionarioPreliminar: React.FC<QuestionarioPreliminarProps> = ({
  area,
  mapeamento,
}) => {
  const { moduloProcessos, postRespostaGestor } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [formValues, setFormValues] = useState<any>({});
  const [disabled, setDisabled] = useState<string[]>([]);

  useEffect(() => {
    moduloProcessos.getMultipleRespostasAuth(mapeamento.versoes);
  }, [mapeamento]);

  useEffect(() => {
    if (moduloProcessos.respostasAuth) {
      setFormValues({});
      setDisabled([]);

      moduloProcessos.respostasAuth.forEach((r: any) => {
        if (r.area_id && r.area_id === area) {
          setFormValues((prevValues: any) => ({
            ...prevValues,
            [r.pergunta_id]: r.value?.[0],
          }));

          setDisabled((prevState: any) => [...prevState, r.pergunta_id]);
        }
      });
    }
  }, [moduloProcessos.respostasAuth, area]);

  const handleInputChange = (fieldName: string, value: any) => {
    setFormValues((prevValues: any) => ({ ...prevValues, [fieldName]: value }));
  };

  const handleSubmit = async () => {
    const save = async () => {
      postRespostaGestor(mapeamento, mapeamento.cliente_id, formValues, area);
    };
    // salva progresso
    save();
  };

  return (
    <form
      style={{
        marginBottom: "3rem",
        marginTop: "1rem",
      }}
    >
      {moduloProcessos.perguntas
        ?.filter((pergunta) =>
          [
            "6669db7bf14624db61281c66",
            "6669dc77f14624db61281c67",
            "6669dcc5f14624db61281c68",
            "6669dd06f14624db61281c69",
            "6669dd66f14624db61281c6a",
          ].includes(pergunta._id)
        )
        ?.map((pergunta, index) => (
          <div key={pergunta._id} className="formItem">
            <label
              className="label"
              style={{
                background: "#5F29CC",
                padding: "0.5rem 1rem",
                color: "#fff",
                fontSize: 14,
                borderRadius: 100,
                fontWeight: 600,
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <b>{index + 1}. </b>
                {pergunta.description}
              </div>
              {pergunta.obs && (
                <Tooltip title={pergunta.obs}>
                  <ExclamationCircleFilled
                    style={{
                      width: "1.5rem",
                      cursor: "pointer",
                      height: "1.5rem",
                    }}
                    color="#fff"
                  />
                </Tooltip>
              )}
            </label>
            {pergunta.tipo === "dropdown" && (
              <Radio.Group
                value={formValues[pergunta._id]}
                onChange={(e: any) =>
                  handleInputChange(pergunta._id, e.target.value)
                }
                style={{ width: "100%", marginLeft: "1.6rem" }}
                disabled={disabled.includes(pergunta._id)}
              >
                <Space direction="vertical">
                  {pergunta?.opcoes?.map((opcao) => (
                    <Radio value={opcao.alternativa}>{opcao.alternativa}</Radio>
                  ))}
                </Space>
              </Radio.Group>
            )}
          </div>
        ))}

      <Button
        style={{
          background: "#32E6BE",
          color: "#333333",
          marginTop: "1rem",
        }}
        type="primary"
        onClick={handleSubmit}
      >
        Concluir
      </Button>
    </form>
  );
};

export default QuestionarioPreliminar;

import React, { useEffect, useState } from "react";
import "./styles.css";

import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Form,
  Modal,
  Radio,
  Table,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import api from "../../../../services/api";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import Task from "../../../../components/Tarefas/Task/Task";

interface Questao {
  _id: string;
  questao: string;
  ordem: number;
  tipo: string;
}

interface Response {
  questao_id: string;
  resposta: string[];
}

const PlanoAcaoConsultor = () => {
  const {
    grauRiscos,
    moduloTask,
    clientes,
    atualizaStatusAcaoFornecedor,
    userInfo,
    allRespFornecedor,
    getFornecedores,
    fornecedores,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const params = useParams();

  const [currentFornecedorAcao, setCurrentFornecedorAcao] =
    useState("continuar");
  const [currentFornecedorId, setCurrentFornecedorId] = useState("");
  const [allRespostas, setAllRespostas] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalPlano, setShowModalPlano] = useState<boolean>(false);

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  const handleShowModalPlano = () => {
    showModal ? setShowModalPlano(false) : setShowModalPlano(true);
  };

  const options = [
    {
      label: "Continuar contrato",
      value: "continuar",
      icon: <CheckOutlined />,
    },
    {
      label: "Descontinuar contrato",
      value: "descontinuar",
      icon: <CloseOutlined />,
    },
    {
      label: "Aditar contrato",
      value: "continuarcontrato",
      icon: <EditOutlined />,
    },
  ];

  useEffect(() => {
    if (
      (!fornecedores ||
        !fornecedores?.find((f: any) => f.cliente_id === params.clienteid)) &&
      params.clienteid
    ) {
      getFornecedores(params.clienteid);
    }

    if (
      fornecedores &&
      fornecedores?.find((f: any) => f.cliente_id === params.clienteid) &&
      params.clienteid &&
      params.fornecedorid
    ) {
      setCurrentFornecedorAcao(
        fornecedores?.find(
          (f: any) =>
            f.cliente_id === params.clienteid && f._id === params.fornecedorid
        )?.status_acao
      );
    }
  }, [fornecedores]);

  useEffect(() => {
    async function fetchData() {
      if (grauRiscos[0]) {
        try {
          const questoesResponse = await api.get("/questoes");
          const questoes: Questao[] = questoesResponse.data;

          console.log(allRespFornecedor);
          setAllRespostas(
            allRespFornecedor.map((fornecedor: any) => {
              const mergedDataArray = fornecedor.respostas.map(
                (resposta: Response) => {
                  const questao = questoes.find(
                    (q: Questao) => q._id === resposta.questao_id
                  );

                  return {
                    ...questao,
                    respostas: resposta.resposta,
                    graus: grauRiscos.filter(
                      (g: any) =>
                        g.questao_id.toString() === questao?._id.toString()
                    ),
                  };
                }
              );

              const sortedMergedData = mergedDataArray.sort(
                (a: any, b: any) => a.ordem - b.ordem
              );

              let allRespData: any = [];

              sortedMergedData.forEach((questao: any) => {
                questao.respostas.map((item: any) => {
                  if (
                    allRespData.filter((item: any) => item._id === questao._id)
                      .length !== 0
                  ) {
                    return;
                  }
                  return allRespData.push({
                    fornecedor_id: fornecedor.fornecedor_id,
                    cliente_id: fornecedor.cliente_id,
                    ...questao,
                    resposta: item,
                    grau: grauRiscos
                      .filter((g: any) => g.questao_id === questao._id)
                      .filter(
                        (g: any) => g.value.toString() === item.toString()
                      )[0]?.grau,
                  });
                });
              });

              return allRespData;
            })
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [params.questionarioid, grauRiscos, allRespFornecedor]);

  const onChange3 = (v: any, fornecedorId: string) => {
    setCurrentFornecedorAcao(v.target.value);
    setCurrentFornecedorId(fornecedorId);
    setShowModal(true);
  };

  return (
    <div className="plano-acao">
      <BackHeader title={"Plano de Ação"} prev />
      <br />
      <h3>Dados da empresa</h3>
      <hr />
      <Table
        size="small"
        style={{ marginTop: "1rem", marginBottom: "2rem" }}
        dataSource={[
          {
            razao_social: clientes?.filter(
              (c: any) => c._id === params?.clienteid
            )[0]?.razao_social,
            representante: clientes?.filter(
              (c: any) => c._id === params?.clienteid
            )[0]?.representante,
            email: clientes?.filter((c: any) => c._id === params?.clienteid)[0]
              ?.email,
            telefone: clientes?.filter(
              (c: any) => c._id === params?.clienteid
            )[0]?.telefone,
          },
        ]}
        pagination={false}
        columns={[
          {
            title: "Razão social",
            dataIndex: "razao_social",
            key: "razao_social",
          },
          {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Telefone",
            dataIndex: "telefone",
            key: "telefone",
          },
          {
            title: "Responsável legal",
            dataIndex: "representante",
            key: "representante",
          },
        ]}
      />
      <br />
      <hr />
      <Table
        columns={[
          { title: "Nome", dataIndex: "nome", key: "name" },
          {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
            render: (cnpj: string) =>
              cnpj?.replace("__.___.___/____-__", "")?.replace("_", ""),
          },
          {
            title: "Ação com fornecedor",
            dataIndex: "status_acao",
            key: "status_acao",
            render: (acao: string) => {
              let icon;
              let color;
              let text;
              if (acao === "continuarcontrato") {
                icon = <EditOutlined />;
                color = "#fcba03";
                text = "Aditar contrato";
              } else if (acao === "continuar") {
                icon = <CheckOutlined />;
                color = "green";
                text = "Continuar contrato";
              } else if (acao === "descontinuar") {
                icon = <CloseOutlined />;
                color = "red";
                text = "Descontinuar contrato";
              }

              return (
                <span style={{ color: color }}>
                  {icon} {text}
                </span>
              );
            },
          },
        ]}
        dataSource={fornecedores
          ?.filter((f: any) => f.cliente_id === params.clienteid)
          .map((fornecedor: any) => {
            return {
              key: fornecedor._id,
              fornecedor_id: fornecedor._id,
              nome: fornecedor.nome,
              cnpj: fornecedor.cnpj,
              status_acao: fornecedor.status_acao,
            };
          })}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {/* ação com fornecedor */}
              <h4>Ação com fornecedor</h4>
              <Form>
                <Form.Item name={"status_acao"}>
                  <Radio.Group
                    defaultValue={record.status_acao}
                    optionType={"button"}
                    value={record.status_acao}
                    size="small"
                    style={{ marginTop: "0.3rem" }}
                    onChange={(e) => {
                      onChange3(e, record.key);
                    }}
                  >
                    {options
                      .filter((opt: any) =>
                        userInfo?.grupos?.find(
                          (gp: any) => gp.name === "assinante"
                        )
                          ? opt.value === record.status_acao
                          : true
                      )
                      .map((option) => (
                        <Radio
                          key={option.value}
                          style={{ marginBottom: "1rem" }}
                          value={option.value}
                        >
                          {option.icon} {option.label}
                        </Radio>
                      ))}
                  </Radio.Group>
                </Form.Item>
              </Form>

              {allRespostas
                .flatMap((array: any) => array) // Nivela o array de arrays
                .filter(
                  (item: any) => item.fornecedor_id === record.fornecedor_id
                )[0] ? (
                <>
                  {/* nao conformidades */}
                  <h4 style={{ marginBottom: "0.3rem" }}>Não conformidades</h4>
                  <Collapse
                    items={allRespostas
                      .flatMap((array: any) => array) // Nivela o array de arrays
                      .filter(
                        (item: any) =>
                          item.fornecedor_id === record.fornecedor_id
                      )
                      .filter(
                        (r: any) =>
                          r.grau === "nao" &&
                          ![
                            11, 14, 17, 20, 23, 24, 25, 26, 27, 28, 30, 31, 34,
                            35, 39,
                          ].includes(r.ordem)
                      )

                      .map((pergunta: any) => {
                        return {
                          key: pergunta._id,
                          label: pergunta.questao,
                          children: (
                            <div
                              key={pergunta + "analiseResp"}
                              style={{ marginBottom: "1.5rem" }}
                            >
                              <h3 style={{ marginBottom: "1rem" }}>
                                Resposta:
                              </h3>
                              <div style={{ marginLeft: "1rem" }}>
                                {pergunta.tipo !== "input"
                                  ? pergunta.opcoes.map((opt: string) => (
                                      <p>
                                        <Checkbox
                                          checked={pergunta.respostas.includes(
                                            opt
                                          )}
                                          style={{ marginRight: "0.5rem" }}
                                        />
                                        {opt}
                                      </p>
                                    ))
                                  : pergunta.resposta}
                              </div>
                              <h3
                                style={{
                                  marginBottom: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                Boas práticas:
                              </h3>
                              <p style={{ marginLeft: "1rem" }}>
                                {pergunta.norma}
                              </p>
                            </div>
                          ),
                        };
                      })}
                  />
                  <br />
                  {/* Pontos de Atenção */}
                  <h4 style={{ marginBottom: "0.3rem" }}>Pontos de atenção</h4>
                  <Collapse
                    items={allRespostas
                      .flatMap((array: any) => array) // Nivela o array de arrays
                      .filter(
                        (item: any) =>
                          item.fornecedor_id === record.fornecedor_id
                      )
                      .filter(
                        (r: any) =>
                          r.grau === "nao" &&
                          [
                            11, 14, 17, 20, 23, 24, 25, 26, 27, 28, 30, 31, 34,
                            35, 39,
                          ].includes(r.ordem)
                      )
                      .map((pergunta: any) => {
                        return {
                          key: pergunta._id,
                          label: pergunta.questao,
                          children: (
                            <div
                              key={pergunta + "analiseResp"}
                              style={{ marginBottom: "1.5rem" }}
                            >
                              <h3 style={{ marginBottom: "1rem" }}>
                                Resposta:
                              </h3>
                              <div style={{ marginLeft: "1rem" }}>
                                {pergunta.tipo !== "input"
                                  ? pergunta.opcoes.map((opt: string) => (
                                      <p>
                                        <Checkbox
                                          checked={pergunta.respostas.includes(
                                            opt
                                          )}
                                          style={{ marginRight: "0.5rem" }}
                                        />
                                        {opt}
                                      </p>
                                    ))
                                  : pergunta.resposta}
                              </div>
                              <h3
                                style={{
                                  marginBottom: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                Boas práticas:
                              </h3>
                              <p style={{ marginLeft: "1rem" }}>
                                {pergunta.norma}
                              </p>
                            </div>
                          ),
                        };
                      })}
                  />

                  <br />
                  {/* Tarefa */}
                  {moduloTask.tasks.filter(
                    (task) => task.modelo_info?._id === record.key
                  )[0] ? (
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        moduloTask.setSelectedTask(
                          moduloTask.tasks.filter(
                            (task) => task.modelo_info?._id === record.key
                          )[0]
                        );
                        handleShowModalPlano();
                      }}
                    >
                      Ver Acompanhamento
                    </Button>
                  ) : (
                    <Alert
                      message="Dentro de 7 dias úteis será liberado uma tarefa de acompanhamento"
                      type="info"
                      showIcon
                    />
                  )}
                </>
              ) : (
                <Alert
                  type="warning"
                  showIcon
                  message="Sem respostas do Parceiro / Fornecedor"
                />
              )}

              <br />
            </>
          ),
          rowExpandable: (record) => record.expandable !== false,
        }}
        size="small"
        // bordered
        // title={() => "Header"}
        // footer={() => "Footer"}
      />

      {/* <h3 style={{ marginBottom: "1rem" }}>Plano de ação</h3>
      <Table
        dataSource={moduloTask.tasks
          ?.filter((task: any) => task.tipo === "chamado" && task.title === "Revisar Plano de ação Parceiros / Fornecedores")
          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
        columns={columns}
      /> */}

      <Task setShowModal={setShowModalPlano} showModal={showModalPlano} />

      <Modal
        title={
          currentFornecedorAcao === "continuar"
            ? "Continuar contrato"
            : currentFornecedorAcao === "descontinuar"
            ? "Descontinuar contrato"
            : "Aditar contrato"
        }
        open={showModal}
        footer={[
          <Button
            type="default"
            form="form-pergunta"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              atualizaStatusAcaoFornecedor(
                currentFornecedorAcao,
                currentFornecedorId
              );
              setShowModal(false);
            }}
            type="primary"
          >
            Prosseguir
          </Button>,
        ]}
        width={600}
        onCancel={handleShowModal}
      >
        {currentFornecedorAcao === "continuar" ? (
          <p style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            Atenção! Antes de prosseguir com esta parceria, é importante
            recordar que algumas inconformidades e possíveis riscos foram
            identificados nas Respostas do Questionário. Recomendamos que seja
            feita uma análise cuidadosa internamente sobre como essas
            inconformidades podem afetar e gerar riscos para a sua empresa antes
            de tomar uma decisão final sobre a continuidade do negócio
          </p>
        ) : currentFornecedorAcao === "descontinuar" ? (
          <p>Você deseja descontinuar fornecedor?</p>
        ) : (
          <p>
            Para resguardar juridicamente a sua empresa, sugere-se que seja
            elaborado um aditivo contratual para que o seu Parceiro Comercial
            tenha como sua obrigação a adequação à LGPD, especialmente em
            relação às inconformidades encontradas nas Respostas do
            Questionário.
            <br />
            <br />
            Para criar um aditivo detalhado e específico, recomenda-se que seja
            aberto um novo chamado na plataforma MeuDPO
          </p>
        )}
      </Modal>
    </div>
  );
};

export default PlanoAcaoConsultor;

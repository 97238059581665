import React, { useEffect, useState } from "react";
import "./styles.css";
import { AnaliseRespostasTypes } from "../../../../../../../@types/analise";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import { Checkbox, Space } from "antd";
import { PerguntaType } from "../../../../../../../context/processosContext";

const Respostas: React.FC<AnaliseRespostasTypes> = ({
  activeCap,
  questId,
  versaoId,
}) => {
  const { moduloMapeamento, moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [currentRespostas, setCurrentRespostas] = useState<any>([]);
  const [questionario, setQuestionario] = useState<any>([]);

  useEffect(() => {
    async function fetchData() {
      if (moduloMapeamento.questionarios && moduloProcessos.respostasAuth) {
        try {
          setQuestionario(
            moduloMapeamento.questionarios.filter(
              (q: any) => q._id.toString() === questId
            )[0]
          );

          const respostas = moduloProcessos.respostasAuth.map(
            (resposta: any) => {
              return {
                ...resposta,
                pergunta: resposta.pergunta,
                respostas: resposta.value,
                graus: resposta.pergunta.opcoes.filter((opt: any) =>
                  resposta.value.includes(opt.alternativa)
                ),
              };
            }
          );
          setCurrentRespostas(respostas);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [moduloMapeamento.questionarios, moduloProcessos.respostasAuth]);

  useEffect(() => {
    versaoId && moduloProcessos.getRespostasAuth(versaoId);
  }, [versaoId]);

  function encontrarIndicePorValor(valorProcurado: any) {
    const capitulosQuestionario = questionario.capitulos?.map(
      (c: any) => c._id
    );

    // Contador global de índice
    let contadorGlobal = 0;

    const indicesPorCapituloEncontrados = capitulosQuestionario.map(
      (capId: string) => {
        const indicesEncontrados = questionario.perguntasRelacao
          ?.filter(
            (r: any) => r.pergunta?.description && r?.capitulo_id === capId
          )
          ?.sort((a: any, b: any) => {
            // Se ambos 'ordem' estão definidos, ordena por 'ordem'
            if (a.pergunta.ordem && b.pergunta.ordem) {
              return a.pergunta.ordem - b.pergunta.ordem;
            }

            // Se 'ordem' não está definido, ordena por 'created_at'
            return (
              new Date(a.pergunta.created_at).getTime() -
              new Date(b.pergunta.created_at).getTime()
            );
          })
          ?.map((objeto: any, indice: number) => {
            contadorGlobal++; // Incrementa o contador global

            if (objeto === valorProcurado) {
              return contadorGlobal;
            }
            return null;
          });

        return indicesEncontrados;
      }
    );

    const indicesValidos = indicesPorCapituloEncontrados
      ?.flat()
      .filter((indice: number) => indice !== null);

    return indicesValidos;
  }

  return (
    <div className="analise__respostas" translate="no">
      {/* <div className="respostas__headerCap">
        <h2>{activeCap}</h2>
      </div>
      <hr className="hrAnalise" /> */}
      <div className="respostas__containner">
        {questionario && questionario.perguntasRelacao && (
          <div className="respostas__pergunta">
            {questionario.perguntasRelacao
              ?.filter(
                (r: any) =>
                  r.capitulo_id === activeCap && r.pergunta?.description
              )
              ?.sort((a: PerguntaType, b: PerguntaType) => {
                // Se ambos 'ordem' estão definidos, ordena por 'ordem'
                if (a.ordem && b.ordem) {
                  return a.ordem - b.ordem;
                }

                // Se 'ordem' não está definido, ordena por 'created_at'
                return (
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime()
                );
              })
              .map((relPergunta: any) => {
                const currentResposta = currentRespostas.filter(
                  (r: any) => r.pergunta_id === relPergunta.pergunta_id
                );
                return (
                  <div key={relPergunta._id + "analiseMultiResp"}>
                    <div
                      onClick={() => console.log(relPergunta.pergunta_id)}
                      className="respostas__pergunta--label"
                      style={{ fontSize: 16 }}
                    >
                      <b>{encontrarIndicePorValor(relPergunta)}. </b>{" "}
                      {relPergunta.pergunta.description}{" "}
                      {/* {resposta.norma && (
                        <Popover
                          title="Norma"
                          trigger={"click"}
                          content={
                            <p
                              style={{
                                maxHeight: 300,
                                overflowX: "auto",
                                padding: "1rem",
                              }}
                            >
                              {resposta.norma}
                            </p>
                          }
                          overlayStyle={{
                            maxWidth: "600px",
                            whiteSpace: "pre-line",
                            maxHeight: "300px",
                          }}
                        >
                          <InfoCircleTwoTone className="info" />
                        </Popover>
                      )} */}
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <Space direction="vertical">
                        {relPergunta.pergunta.tipo !== "input" ? (
                          relPergunta.pergunta.opcoes.map((opt: any) => {
                            let allRespostas: any = [];
                            currentResposta.forEach((r: any) =>
                              allRespostas.push(...r.value)
                            );

                            const currentGrau =
                              currentResposta?.[0]?.graus?.filter((g: any) =>
                                g.alternativa?.includes(opt?.alternativa)
                              )?.[0];

                            return (
                              <p>
                                <Checkbox
                                  checked={
                                    currentResposta.filter((resp: any) =>
                                      resp.respostas.includes(opt.alternativa)
                                    )?.[0]
                                      ? true
                                      : false
                                  }
                                >
                                  {opt.alternativa}{" "}
                                  {currentResposta.filter((resp: any) =>
                                    resp.respostas.includes(opt.alternativa)
                                  ).length > 1 && (
                                    <>
                                      {" "}
                                      -{" "}
                                      <b
                                        className={
                                          currentGrau.grau_descricao ===
                                            "Conforme" ||
                                          currentGrau.grau_descricao ===
                                            "Baixo risco"
                                            ? "respostas__pergunta--label__conforme"
                                            : currentGrau.grau_descricao ===
                                                "Não conforme" ||
                                              currentGrau.grau_descricao ===
                                                "Alto risco"
                                            ? "respostas__pergunta--label__inconforme"
                                            : currentGrau.grau_descricao ===
                                              "Médio risco"
                                            ? "respostas__pergunta--label__orange"
                                            : "respostas__pergunta--label__blue"
                                        }
                                      >
                                        {currentResposta.filter((resp: any) =>
                                          resp.respostas.includes(
                                            opt.alternativa
                                          )
                                        ).length !== 1 && (
                                          <>
                                            {
                                              currentResposta.filter(
                                                (resp: any) =>
                                                  resp.respostas.includes(
                                                    opt.alternativa
                                                  )
                                              ).length
                                            }{" "}
                                            resposta
                                            {currentResposta.filter(
                                              (resp: any) =>
                                                resp.respostas.includes(
                                                  opt.alternativa
                                                )
                                            ).length !== 1 && "s"}{" "}
                                          </>
                                        )}

                                        {currentGrau.grau_descricao && (
                                          <span
                                            style={{
                                              fontSize: "0.6rem",
                                            }}
                                          >
                                            ({currentGrau.grau_descricao})
                                          </span>
                                        )}
                                      </b>
                                    </>
                                  )}
                                </Checkbox>
                              </p>
                            );
                          })
                        ) : (
                          <p className="respostas__pergunta--label__blue">
                            {/* Conhecimento de processo */}
                            {currentResposta[0]?.value[0]}
                          </p>
                        )}
                      </Space>
                    </div>
                    {/* <br />
                    <h4>Boas práticas</h4>
                    <br />

                    <div
                      style={{ marginLeft: "1rem" }}
                      dangerouslySetInnerHTML={{
                        __html: relPergunta.pergunta.recomendacao,
                      }}
                    /> */}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Respostas;
